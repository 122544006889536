<template>
  <div>
    <LayoutSingle
      :header="true"
      :title="'Конкурсы'"
      :max-width="1266"
    >
      <template #contactComponent>
        <div class="link__container" v-for="(item, i) in competitionLinks" :key="i">
          <p class="link__text">{{ item.text }}</p>
          <p class="link__timeframe">{{ item.timeframe }}</p>
          <router-link class="link-btn" :to="item.link">
            <p>Открыть</p>
          </router-link>
        </div>
      </template>
    </LayoutSingle>
    <LayoutSingle
      :header="true"
      :title="'Сборы отчетности'"
      :max-width="1266"
    >
      <template #contactComponent>
        <div class="link__container" v-for="(item, i) in sborLinks" :key="i">
          <p class="link__text">{{ item.text }}</p>
          <p class="link__timeframe">{{ item.timeframe }}</p>
          <router-link class="link-btn" :to="item.link">
            <p>Открыть</p>
          </router-link>
        </div>
      </template>
    </LayoutSingle>
    <LayoutSingle
      :header="true"
      :title="'Иные сборы'"
      :max-width="1266"
    >
      <template #contactComponent>
        <div class="link__container" v-for="(item, i) in etcSbors" :key="i">
          <p class="link__text">{{ item.text }}</p>
          <p class="link__timeframe">{{ item.timeframe }}</p>
          <router-link class="link-btn" :to="item.link">
            <p>Открыть</p>
          </router-link>
        </div>
      </template>
    </LayoutSingle>
  </div>
</template>

<script>
import {LayoutSingle} from "@frontenddevelopers/ined-components/src/lib"

export default {
  name: "Sbors",
  components: {LayoutSingle},
  data() {
    return {
      competitionLinks: [
        {
          text: 'Всероссийский открытый конкурс для назначения стипендий Президента Российской Федерации для обучающихся за рубежом',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/asd'
        },
        {
          text: 'Всероссийский открытый конкурс  для назначения именных стипендий',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/zxc'
        },
      ],
      sborLinks: [
        {
          text: 'Сбор отчетов о выплате стипендий Президента РФ и стипендий Правительства РФ по приоритетным направлениям (для организаций, осуществляющих образовательную деятельность)',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/asd'
        },
        {
          text: 'Сбор приказов  о назначении стипендии Правительства РФ по приоритетным направлениям',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/vbn'
        },
        {
          text: 'Сбор отчетов о выплате стипендий Президента РФ и стипендий Правительства РФ по приоритетным направлениям (для ФОИВов)',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/vghj'
        },
        {
          text: 'Сбор отчетов о выплате стипендий Президента РФ и стипендий Правительства РФ по приоритетным направлениям (для организаций, осуществляющих образовательную деятельность)',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/iyu'
        },
      ],
      etcSbors: [
        {
          text: 'Сбор контактов ответственных лиц за прием заявок от претендентов',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/eqw'
        },
        {
          text: 'Сбор сведений об историях успеха стипендиатов',
          timeframe: 'ДД.ММ.ГГГГ – ДД.ММ.ГГГГ',
          link: '/zxc'
        },
      ],
    }
  }
}
</script>

<style lang="scss" scoped>
.link__container {
  display: flex;
  flex-direction: row;
  min-height: 70px;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #EBEFF2;

  .link__text {
    font-size: 16px;
    line-height: 19px;
    display: flex;
    color: #455A64;
    margin-bottom: 0;
    margin-left: 25px;
    max-width: 780px;
  }

  .link__timeframe {
    font-size: 15px;
    line-height: 18px;
    display: flex;
    color: #89A0B0;
    margin-right: 31px;
    margin-left: auto;
    margin-bottom: 0;
  }

  .link-btn {
    display: flex;
    width: 96px;
    padding: 5px 17px;
    margin-bottom: 0;
    margin-right: 25px;
    border: 1px solid #0288D1;
    border-radius: 6px;
    letter-spacing: 0.01em;
    color: #0288D1;
    font-size: 14px;
    line-height: 16px;
    cursor: url(../assets/icons/cursor.svg), auto;

    &:hover {
      color: #EB722E;
      border: 1px solid #EB722E;
    }

    p {
      margin-bottom: 0;
    }
  }
}
</style>